import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch, useHistory, useLocation, useRouteMatch} from "react-router-dom";
import {ConfigProvider, message} from 'antd';
import {IntlProvider} from "react-intl";
import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";


import {
    fetchRecords,
    getActiveCompany,
    getUser,
    setApplicationAuthorization,
    setExpiresSignature, setIdleId,
    setInitUrl, setWarningId, userSignOut
} from "../../appRedux/actions";
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "../../appRedux/actions";

import {
    LAYOUT_TYPE_BOXED,
    LAYOUT_TYPE_FRAMED,
    LAYOUT_TYPE_FULL,
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import asyncComponent from "../../util/asyncComponent";
import CircularProgress from "../../components/CircularProgress";
import {GET_USER_AUTHORIZATION, LOGIN_PAGE} from "../../constants/ServerUrl";
import {USER_TOKEN_SET} from "../../constants/ActionTypes";


const RestrictedRoute = ({component: Component, location, token, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            token
                ? <Component {...props} />
                : <CircularProgress/>}
    />;


const App = () => {

    const dispatch = useDispatch();
    const {locale, navStyle, layoutType} = useSelector(({settings}) => settings);
    const {token, initURL, authUser, idleId, warningId} = useSelector(({auth}) => auth);
    const {loading, error} = useSelector(({common}) => common);

    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();


    // useEffect(() => {
    //     let link = document.createElement('link');
    //     link.type = 'text/css';
    //     link.rel = 'stylesheet';
    //     link.href = "/css/style.css";
    //     link.className = 'gx-style';
    //     document.body.appendChild(link);
    // });

    useEffect(() => {
        if (initURL === '') {
            console.log("set init url");
            console.log(location.pathname);
            dispatch(setInitUrl(location.pathname));
        }
        const params = new URLSearchParams(location.search);

        if (params.has("signature") && params.has("expires")) {
            dispatch(setExpiresSignature({signature: params.get('signature'), expires: params.get('expires')}));
        }
        if (params.has("referer")) {
            let token = JSON.stringify(params.get('referer'));
            localStorage.setItem("token", token);
            dispatch({type: USER_TOKEN_SET, payload: token});
            const { pathname } = location;
            console.log('TOKEN: '+ token)
            console.log('PATHNAME: '+ pathname)
            if (window.location.search) {
                //history.replace(pathname);
                window.open(pathname, '_blank');
                //window.location.href = window.location.pathname;
            }
            
        } else {
            let token = localStorage.getItem("token");
            if (!token) {
                let redirectUrl;
                const currentPath = window.location.href;
                if (currentPath != LOGIN_PAGE) {
                    redirectUrl = `${LOGIN_PAGE}?redirect_url=${encodeURIComponent(currentPath)}`;
                } else {
                    redirectUrl = LOGIN_PAGE
                }
                window.location.href = redirectUrl;
            }
            
        }
        resetTimer();
        resetWarning();

        //
        // if (params.has("theme")) {
        //     dispatch(setThemeType(params.get('theme')));
        // }
        // if (params.has("nav-style")) {
        //     dispatch(onNavStyleChange(params.get('nav-style')));
        // }
        // if (params.has("layout-type")) {
        //     dispatch(onLayoutTypeChange(params.get('layout-type')));
        // }
        // setLayoutType(layoutType);
        // setNavStyle(navStyle);
    }, [dispatch, initURL, layoutType, location.pathname, location.search, navStyle]);
    const resetTimer = () => {

        clearTimeout(idleId);
        let idle = setTimeout(() => {
            dispatch(userSignOut());

        }, 3800000);
        dispatch(setIdleId(idle));

    }


    const resetWarning = () => {

        clearTimeout(warningId);
        let warnMe = setTimeout(() => {
            message.warn("In-Activity Detect! You will be signed out in 5 minutes");
        }, 3500000);
        dispatch(setWarningId(warnMe));

    }

    const setLayoutType = (layoutType) => {
        if (layoutType === LAYOUT_TYPE_FULL) {
            document.body.classList.remove('boxed-layout');
            document.body.classList.remove('framed-layout');
            document.body.classList.add('full-layout');
        } else if (layoutType === LAYOUT_TYPE_BOXED) {
            document.body.classList.remove('full-layout');
            document.body.classList.remove('framed-layout');
            document.body.classList.add('boxed-layout');
        } else if (layoutType === LAYOUT_TYPE_FRAMED) {
            document.body.classList.remove('boxed-layout');
            document.body.classList.remove('full-layout');
            document.body.classList.add('framed-layout');
        }
    };

    const setNavStyle = (navStyle) => {
        if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
            navStyle === NAV_STYLE_DARK_HORIZONTAL ||
            navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
            navStyle === NAV_STYLE_ABOVE_HEADER ||
            navStyle === NAV_STYLE_BELOW_HEADER) {
            document.body.classList.add('full-scroll');
            document.body.classList.add('horizontal-layout');
        } else {
            document.body.classList.remove('full-scroll');
            document.body.classList.remove('horizontal-layout');
        }
    };
    const getAuthorization = () => {
        fetchRecords(GET_USER_AUTHORIZATION).then((res) => {
            dispatch(setApplicationAuthorization(res.authorizations));

        }).catch(err => {
            console.log(err);
        });
    }
    useEffect(() => {
        dispatch(getUser());
        dispatch(getActiveCompany());
        getAuthorization();
        if (!loading) {
            //change this to if user does not have access to this application
            //redirect to the home page
            if (authUser) {
                if (authUser.status < 4) {
                    //window.location.href = LOGIN_PAGE;
                     //add extra condition to accommodate for redirects
                     let redirectUrl;
                     const currentPath = window.location.href;
                     if (currentPath != LOGIN_PAGE) {
                         redirectUrl = `${LOGIN_PAGE}?redirect_url=${encodeURIComponent(currentPath)}`;
                     } else {
                         redirectUrl = LOGIN_PAGE
                     }
                     window.location.href = redirectUrl;
                } else {

                    history.push(initURL);
                }

            } else {
                if (!authUser && error) {

                    window.location.href = LOGIN_PAGE;
                }
            }
        }
    }, [token, initURL]);

    const currentAppLocale = AppLocale[locale.locale];

    return (
        <ConfigProvider locale={currentAppLocale.antd}>
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}>

                <Switch>

                    <RestrictedRoute path={`${match.url}`} token={token} location={location}
                                     component={MainApp}/>
                </Switch>
            </IntlProvider>
        </ConfigProvider>
    )
};

export default memo(App);
